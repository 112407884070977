import React from "react"
import styled from 'styled-components';
import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  InnerContainer,
  Spacer,
  Column,
  Grid,
  Media,
} from 'components/ui';
import sc from 'config/theme';
import $ from 'jquery';
import { Script, Sans, Serif } from 'Typography';
import bp from 'config/responsive';

import {  SearchResultsSignup} from 'components/sections';


import { ViewportEnterAnimation } from 'components/ui';
import { functionAboutAccordion } from 'motion/about.js';

const Container = styled.div`
  background: ${sc.colors.white};
  padding-top: 60px;
  padding-bottom: 60px;

  &.gray-bg {
    background: #F5F5F5;
    max-width: initial !important;
  }

  & .aboutOptions {
    opacity: 1 !important;
  }

  & .transparentBG {



    & > div {

      background: transparent;
      padding-top: 0;
      padding-bottom: 0;
    }

  }


  ${bp.computer} {
    padding-top: 140px;
    padding-bottom: 100px;
    min-height: 70vh;
  }

  & h2 {
    color: #0B1D3F;
    margin: 30px 0;
  }

  & ul.bold ,
  & b, strong {
    font-weight: bold;
  }

  & ul ul {
    margin: 15px 0;
  }

  & .table{
    display: table;
    width: 100%;
    max-width: 800px;
    margin: 40px 0;

    .table-row {
      display: table-row;

      &:last-child {
        .table-col {
          border-bottom: 1px solid #cecece;
        }
      }

      .table-col {
        display: table-cell;
        padding: 5px 10px;
        border-left: 1px solid #cecece;
        border-top: 1px solid #cecece;
        width: 33%;
        vertical-align: middle;

        &:last-child {
          border-right: 1px solid #cecece;
        }

        p {
          margin-bottom: 10px;
        }
      }

      &.row-header {
        font-weight: bold;
        color: #0a1e3f;
        background: #ececec;
      }
    }
  }

`

const StyledGrid = styled(Grid)`
  &&& {
    padding-top: 50px;
    margin-bottom: 30px;

    ${bp.tablet} {
      margin-bottom: 100px;
    }

    ${bp.computer} {
      padding-top: 0;
      margin-bottom: 100px;
    }
  }
`;

const Title = styled.div`
  margin-bottom: 20px;

  ${bp.computer} {
    margin-bottom: 20px;
  }
`;

const Paragraph = styled.p`
  display: block;

`;

const WhiteBox = styled.div`
  background: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 50px 25px 50px;
  max-width: 1560px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 40px;

  ${bp.tablet} {
    padding: 50px 40px 50px;
  }

  &.stamp {
    padding: 10px 25px 50px;
    margin-bottom: 0;

    ${bp.tablet} {
      padding: 10px 50px 50px;
    }
    ${bp.computer} {
      padding: 50px 100px 70px;
      margin: 40px auto 0;
    }
  }
  &.dflex {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    ${bp.computer} {
      flex-direction: row;
    }

    & > div {
      width: 100%;
      max-width: inherit;
      margin-bottom: 40px;

      ${bp.computer} {
        max-width: 320px;
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          border-bottom: 1px solid #f1f1f1;

          color: rgba(0, 0, 0, 0.5);

          a {
            padding: 15px 0;
            display: block;
            color: rgba(0, 0, 0, 0.5);
            text-decoration: none;

            &:hover, &:hover {
              color: rgba(0, 0, 0, 1);
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  &.animateBox {

    /* ${bp.computer} {
      opacity: 0;
    } */
  }

  ${bp.computer} {
    margin: 40px auto 0;
  }

  img {
    display: none;
  }
`;

const PaddingBox = styled.div`
  img {
    display: none;
  }

  ${bp.tablet} {
    padding: 0 40px;
  }
  ${bp.computer} {
    padding: 0 ;
  }
`


const ProeprtyAlerts = () => (

  <Media
        breakpoint={bp.computer}
        renderIfTrue={() => (

  <Layout>
    <SEO title="Property Alert" description="Check out the latest job openings at Ernest Brooks International"/>
    <Container className="gray-bg">
      <InnerContainer>
        <StyledGrid>

          <Column width={2}></Column>
          <Column width={8}>

            <WhiteBox>
              <SearchResultsSignup />
            </WhiteBox>

          </Column>
          <Column width={2}></Column>

        </StyledGrid>

      </InnerContainer>
    </Container>

  </Layout>

)}
  renderIfFalse={() => (  

  <Layout>
      <SEO title="Property Alert" description="Check out the latest job openings at Ernest Brooks International"/>
      <Container>
        <InnerContainer>
          <StyledGrid>
            <Column width={12}>

            <PaddingBox>
              <SearchResultsSignup />
            </PaddingBox>
            </Column>
          </StyledGrid>

        </InnerContainer>
      </Container>

    </Layout>
)} />
)

export default ProeprtyAlerts